@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  background-color: black!important ;
}


h2{
  color:white;
  font-family:'Roboto', sans-serif;
  font-weight: 500;
  font-size: 2.25rem !important;
}

h1{
  font-family:'Roboto', sans-serif;
  font-weight: 500;
}

.dark{
  color:black!important
}

.site-container{
  min-height: 100vh;
  background-color: black;
}
/* header{
  background-color: rgb(142,142,147);
  padding:1rem;
}

header a{
  color:white;
  text-decoration: none;
  font-weight: bold;
} */

main {
  flex:1;
}

.app-content-container{
  width: 100%;
  height:100%;
  margin:0 !important;
  padding: 0 !important;
}

.home-main{
  width: 100%;
  height:100%;
  display: flex;
  flex-flow: column;
}

.home-text-container{
  color:white;
  text-align: center;
  
}

.home-text{
  font-size: 5rem;
  text-shadow: rgb(104, 101, 100) 0px 4px 2px;
  font-family: 'SF Bold','sans-serif'!important;
}

.logo-text{
  font-size: 4rem;
}

.footer-text{
  color:black
}
.rating i{
  color:#ffb700
}


.products-list{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.product{
  margin:5px;
}




.product-description{
  padding-top:5px;
  line-height: 0.5rem;
}

.img-thumbnail {
  height:80px !important;
}

.small-container{
  max-width: 600px !important;
}

.dropdown-menu{
  border-radius: 2px !important;
  background-color: #fffffffc !important;
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  margin-top:0.5rem;
  color: #a0a0a0
}

.checkout-steps > div.active {
  border-bottom: 0.2rem solid #d2260f;
  margin-top:0.5rem;
  color: #ab200e
}

.link{
  text-decoration: none;
  color:#308bc8
}

.navbar{
  position: fixed!important;
  padding:20px!important;
  top:0;
}

.shadow-card{
  box-shadow: rgb(104, 101, 100) -1px 2px 3px;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;

}

.how-card{
  box-shadow: rgb(104, 101, 100) -1px 2px 3px;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  height:300px !important;
  padding:30px;
  text-align: center;
}

.statement-container{
  background-color: white;
  margin: auto;
  max-width: none !important;
  padding-bottom: 3rem;
}

.statement-text{
  color:#050505;
  font-size: 2rem;
  font-style: italic;
}

.statement-title{
  color:#5a5a5a;
  text-align: center;
  margin-top: 4rem;
}

#custom-nav{
  width: 100% !important;
  background-color: #000000d1 !important;
  z-index: 1;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

@font-face {
  font-family: "SF Light";
  src: url("./fonts/SF-Compact-Display-Light.otf");
}

@font-face {
  font-family: "SF Light Italic";
  src: url("./fonts/SF-Compact-Text-LightItalic.otf");
}

@font-face {
  font-family: "SF Bold";
  src: url("./fonts/SF-Compact-Display-Bold.otf");
}