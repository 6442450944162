.article-container{
    background-color: white;
    margin-left: auto;
}

.body-padding{
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
}


@media screen and (max-width:1500px) {
    .body-padding{
        padding-left: 5%;
        padding-right: 5%;
    }
}

#title{
    padding-top: 6rem;
    
}

.article-container h2{
    color: black;
    font-size: 6!important;
    font-family: 'SF Light','sans-serif'!important;
    width: 100%;
    padding: 1rem 0rem;
    font-weight: 500;
}

.article-container h3{
    color: black;
    font-size: xx-large!important;
    font-family: 'SF Light','sans-serif'!important;
    width: 100%;
    padding: 1rem 0rem;
    font-weight: bold;
}

.article-container h1{
    text-align: left;
    width: 100%;
    font-size: xx-large !important;
    font-family: 'SF Light','sans-serif'!important;
    padding: 0rem 0rem;
    font-weight: bold!important
}

.article-container p{
    padding-bottom: 0.5rem;
    width: 100%;
    font-size: large;

}

.article-container .source{
    line-break: anywhere !important;
    padding-bottom: 0!important;
}

.header-container{
    columns: 2;
    flex-direction: row;
    justify-items: center;
    flex-direction: row;
    
}

.div-padding{
    padding: 0.5rem;
}

p{
    line-height:2rem
}

li{
    line-height:2rem
}